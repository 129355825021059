import { h } from "preact";
import { Route, Router } from "preact-router";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import BatteryPassport from "../routes/battery-passport";

export default function App() {
  return (
    <div id="app">
      <main>
        <Router>
          <Route path="/" component={Home} />
          <Route
            path="/battery-passport/:batteryId"
            component={BatteryPassport}
          />
        </Router>
      </main>
    </div>
  );
}
